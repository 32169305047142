<template>
  <div class="container position-relative">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop bg-dark" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Error -->
    <error-translation-alert
      v-else-if="isErrorActive"
      ref="errorAlert"
      :error="activeError"

      class="container"
    />
    <!-- end::Error -->

    <!-- begin::Key figures -->
    <template v-else>
      <!-- begin::Default key figures -->
      <div class="row">
        <div class="col-12 mt-3 col-md-4 border-bottom form-group">
          <label class="text-upper">Sunbed hours</label>
          <div class="row">
            <h5 class="col overflow-ellipsis">{{ format.sunbedHours(equipment.getControllerHours('unit') || 0) }}</h5>
          </div>
        </div>

        <div class="col-12 mt-3 col-md-4 border-bottom form-group">
          <label class="text-upper">Number of sessions</label>
          <div class="row">
            <h5 class="col overflow-ellipsis">{{ (data.keyFigures && data.keyFigures.sessions && data.keyFigures.sessions.total || 0) }}</h5>
          </div>
        </div>

        <div class="col-12 mt-3 col-md-4 border-bottom form-group">
          <label class="text-upper">Average session time</label>
          <div class="row">
            <h5 class="col overflow-ellipsis">{{ averageSessionTimeInMinutes }} {{ $tc('plural.minutes', averageSessionTimeInMinutes) }}</h5>
          </div>
        </div>
      </div>
      <!-- end::Default key figures -->

      <!-- begin::Temperature key figures -->
      <div
        v-if="temperatureKeyFiguresForEquipment.length > 0"
        class="row"
      >
        <div
          v-for="(row) in temperatureKeyFiguresForEquipment"
          :key="`equipment.${equipment._meta.guid}.key-figure.temperature.${row.sensor}`"
          class="col-12 mt-3 col-md-4 border-bottom form-group"
        >
          <label class="text-upper">Average temperature {{ ($tu(`sunbeds.models.${equipment.model}.temperatures.${row.sensor}.name`) || `sensor '${row.sensor}'`).toLowerCase() }}</label>
          <div class="row">
            <h5 class="col overflow-ellipsis">
              <template v-if="device && device.type === 'MYLUXURA_MODULE' && getAverageTemperatureOfSensor(row.sensor) !== null">{{ (`${(getAverageTemperatureOfSensor(row.sensor) / 10).toFixed(1)} &deg;C`) }}</template>
              <template v-else>N/A</template>
            </h5>
          </div>
        </div>
      </div>
      <!-- end::Temperature key figures -->

      <!-- begin::Option key figures -->
      <div
        v-if="counterKeyFigures.length > 0"
        class="row"
      >
        <div
          v-for="(row) in counterKeyFigures"
          :key="`equipment.${equipment._meta.guid}.key-figure.counter.${row.counter}`"
          class="col-12 mt-3 col-md-4 border-bottom form-group"
        >
          <label class="text-upper">{{ ($tu(`sunbeds.models.${equipment.model}.maintenance.hours.${row.counter}.name`) || row.counter) }} usage</label>
          <div class="row">
            <h5 class="col overflow-ellipsis">
              <template v-if="device && device.type === 'MYLUXURA_MODULE' && getUsageOfCounter(row.counter) !== null">
                {{ (`${(getUsageOfCounter(row.counter) * 100).toFixed(1)}%`) }}
              </template>
              <template v-else>N/A</template>
            </h5>

            <div class="col-auto">
              <i class="svg-icon text-primary cursor-pointer" v-b-tooltip.hover="`Based on the amount of time this option was used during a session in the last 30 days.`">
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </i>
            </div>
          </div>
        </div>
      </div>
      <!-- end::Option key figures -->
    </template>
    <!-- end::Key figures -->
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin } from '@vedicium/core-vue';
import moment from 'moment-timezone';

import format from '@/libs/format';
import Equipment, { SunbedHealth } from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [equipmentMixin, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      data: {
        keyFigures: null,
        temperatureKeyFigures: null,
        counterKeyFigures: null,
      },

      format,
    };
  },
  computed: {
    averageSessionTime () {
      if (!this.data.keyFigures || !this.data.keyFigures.sessions) {
        return null;
      }

      return (this.data.keyFigures.sessions.session_time / this.data.keyFigures.sessions.total);
    },
    averageSessionTimeInMinutes () {
      return Math.floor((this.averageSessionTime || 0) / 60);
    },

    temperatureKeyFiguresForEquipment () {
      const health = SunbedHealth.find((row) => row.model.includes(this.equipment.model));
      return (health && health.temperatures && health.temperatures.filter((temperatureRow) => temperatureRow.key_figure === true, [])) || [];
    },

    counterKeyFigures () {
      const counters = ['ft', 'st', 'xsens1', 'xsens2', 'xsens3'];

      switch (this.equipment.model) {
        case 'luxura_jewel': {
          // Add Luxura Jewel facetanners
          counters.push('ftExt', 'ftBench');

          // Only add airco when the option is available.
          if (this.equipment.specifications.options.includes('Climate Control')) {
            counters.push('airco', 'ccHeater');
          }

          break;
        }

        default: {
          // Add default counters
          counters.push('ledUva', 'ledRed', 'airco', 'ccHeater');
          break;
        }
      }

      return (this.data.counterKeyFigures || []).filter((row) => counters.includes(row.counter), []);
    },
  },

  async mounted () {
    this.$set(this, 'isLoading', true);

    try {
      this.$set(this.data, 'keyFigures', await this.getKeyFigures());
      this.$set(this.data, 'temperatureKeyFigures', await this.getTemperatureKeyFigures());
      this.$set(this.data, 'counterKeyFigures', await this.getCounterKeyFigures());
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async getKeyFigures () {
      return this.$ws.get(`${Equipment.uri}/statistics/key-figures`, {
        query: {
          equipment: this.equipment._meta.guid,
          offset: this.equipment.location.timezone || 'UTC',
        },
      });
    },

    async getTemperatureKeyFigures () {
      const dateRange = [
        moment.tz(this.equipment.location.timezone || 'UTC').subtract(30, 'days').startOf('day'),
        moment.tz(this.equipment.location.timezone || 'UTC'),
      ];

      return this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/statistics/key-figures/temperatures`, {
        query: {
          'filters[controller_state]': [7, 10],
          'filters[log_date|gte]': dateRange[0].valueOf(),
          'filters[log_date|lte]': dateRange[1].valueOf(),
        },
      });
    },
    getAverageTemperatureOfSensor (sensor = null) {
      if (!this.data.temperatureKeyFigures) {
        return null;
      }

      const temperature = this.data.temperatureKeyFigures.find((row) => row.sensor === sensor);
      return (temperature && temperature.value) || null;
    },

    async getCounterKeyFigures () {
      const dateRange = [
        moment.tz(this.equipment.location.timezone || 'UTC').subtract(30, 'days').startOf('day'),
        moment.tz(this.equipment.location.timezone || 'UTC'),
      ];

      return this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/statistics/key-figures/counters`, {
        query: {
          'filters[log_date|gte]': dateRange[0].valueOf(),
          'filters[log_date|lte]': dateRange[1].valueOf(),
        },
      });
    },
    getUsageOfCounter (counterKey = null) {
      if (!this.data.counterKeyFigures) {
        return null;
      }

      const unitCounter = this.data.counterKeyFigures.find((row) => row.counter === 'unit');
      const counter = this.data.counterKeyFigures.find((row) => row.counter === counterKey);
      if (!unitCounter || !counter) {
        return null;
      }

      return counter.value / unitCounter.value;
    },
  },
};
</script>
